import React from 'react';
import autobind from 'autobind-decorator';
import imagesLoaded from 'imagesloaded';

import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {DataHook as ProductImagesDataHook} from '../../../common/components/ProductItem/ProductImage/ProductImage';
import {EmptyGallery} from './EmptyGallery/EmptyGallery';
import {IGalleryGlobalProps} from '../../galleryGlobalStrategy';
import {LoadMoreButton} from './LoadMoreButton/LoadMoreButton';
import {GridType, LoadMoreType, PaginationType, PaginationTypeName} from '../../../types/galleryTypes';
import {withGlobals} from '../../../globalPropsContext';
import {DEFAULT_MOBILE_PRODUCTS_COUNT} from '../../../constants';
import {ProductListGrid} from './ProductListGrid';
import {ProductItemWithGlobals} from '../../../common/components/ProductItem/ProductItem';
import {Pagination, PaginationProps} from './Pagination';

export interface ProductListProps extends IGalleryGlobalProps, IProvidedTranslationProps {
  hasFilters: boolean;
}

interface ProductListState {
  inBrowser: boolean;
}

@withGlobals
@withTranslations()
@autobind
export class ProductList extends React.Component<ProductListProps, ProductListState> {
  private readonly listRef: React.RefObject<HTMLDivElement>;
  private imagesLoaded = false;
  private isInteractive = false;
  private focusedAt: number = -1;

  public constructor(props: ProductListProps) {
    super(props);
    this.listRef = React.createRef();
    this.state = {
      inBrowser: false,
    };
  }

  public componentDidMount(): void {
    this.setState({inBrowser: true}, () => {
      imagesLoaded(document.querySelectorAll(`[data-hook="${ProductImagesDataHook.Images}"]`), () => {
        this.imagesLoaded = true;
        this.reportLoad();
      });
    });
  }

  private reportLoad() {
    if (this.props.globals.isInteractive && this.imagesLoaded) {
      this.props.globals.appLoadBI.loaded();
    }
  }

  public componentDidUpdate(prevProps: IGalleryGlobalProps) {
    if (!this.isInteractive && this.props.globals.isInteractive) {
      this.isInteractive = true;
      /* istanbul ignore next: hard to test it */
      this.props.globals.updateLayout && this.props.globals.updateLayout();
      this.reportLoad();
    }

    this.focusedAt = prevProps.globals.focusedProductIndex;
  }

  public render() {
    const {products} = this.props.globals;
    return (
      <section data-hook="product-list" aria-label={this.props.t('galleryRegionSR')}>
        {products.length === 0 ? this.getEmptyList() : this.getProductList()}
      </section>
    );
  }

  private get maxProductsPerPage(): number {
    if (this.props.globals.isAutoGrid) {
      return this.props.globals.styleParams.numbers.gallery_productsCount;
    }
    if (this.props.globals.isMobile) {
      return DEFAULT_MOBILE_PRODUCTS_COUNT;
    }
    return this.props.globals.styleParams.numbers.galleryColumns * this.props.globals.styleParams.numbers.galleryRows;
  }

  private getEmptyList() {
    return <EmptyGallery hasFilters={this.props.hasFilters} />;
  }

  private getFocusableItemIndex() {
    const {isFirstPage, focusedProductIndex} = this.props.globals;
    const prevFocusedProductIndex = this.focusedAt;

    if (!isFirstPage && focusedProductIndex !== prevFocusedProductIndex) {
      return this.props.globals.focusedProductIndex;
    }

    return -1;
  }

  private getNumberOfVisibleProducts(): number {
    if (this.gridType() === GridType.AUTO && this.props.globals.isFirstPage) {
      return this.props.globals.styleParams.numbers.gallery_productsCount;
    }

    if (this.props.globals.isFirstPage) {
      return this.maxProductsPerPage;
    }

    return this.props.globals.products.length;
  }

  private gridType() {
    return this.props.globals.isAutoGrid ? GridType.AUTO : GridType.MANUAL;
  }

  private getProductList() {
    const {products, isMobile, styleParams} = this.props.globals;
    const nextProducts = products.slice(0, this.getNumberOfVisibleProducts());

    return (
      <div ref={this.listRef}>
        <ProductListGrid
          products={nextProducts}
          isMobile={isMobile}
          gridType={this.gridType()}
          renderKey={String(styleParams.numbers.gallery_productSize)}
          focusAt={this.getFocusableItemIndex()}
          ProductItem={ProductItemWithGlobals}
        />
        {this.renderLoadMore()}
      </div>
    );
  }

  private renderLoadMore() {
    const {loadMoreType} = this.props.globals;

    switch (loadMoreType) {
      case LoadMoreType.PAGINATION:
        return this.renderPagination();
      case LoadMoreType.BUTTON:
      default:
        return this.shouldShowLoadMoreButton() && this.getLoadMoreButton();
    }
  }

  private shouldShowLoadMoreButton(): boolean {
    const {
      globals: {isFirstPage, hasMoreProductsToLoad, totalProducts},
    } = this.props;

    if (isFirstPage) {
      return this.maxProductsPerPage < totalProducts;
    }
    return hasMoreProductsToLoad;
  }

  private getLoadMoreButton() {
    return <LoadMoreButton loadMoreClicked={this.loadMoreClicked} />;
  }

  private async loadMoreClicked() {
    this.props.globals.setProductsPerPage(this.maxProductsPerPage);
    await this.props.globals.loadMoreProducts(this.getNumberOfVisibleProducts());
  }

  private scrollToTop() {
    if (window.Wix) {
      window.Wix.scrollTo(0, 0, {scrollAnimation: true});
    } else {
      window.scrollTo({left: 0, top: this.listRef.current.offsetTop, behavior: 'smooth'});
    }
  }

  private renderPagination() {
    const {
      currentPage,
      totalProducts,
      handlePagination,
      styleParams: {
        numbers: {gallery_paginationFormat},
        booleans: {gallery_paginationFirstLastArrows},
      },
      isMobile,
    } = this.props.globals;
    const totalPages = Math.ceil(totalProducts / this.maxProductsPerPage);
    const showFirstLastNavButtons = gallery_paginationFirstLastArrows && totalProducts <= 10_000;
    const paginationMode: PaginationTypeName =
      isMobile || gallery_paginationFormat === PaginationType.COMPACT ? 'compact' : 'pages';

    const props: PaginationProps = {
      currentPage,
      paginationMode,
      totalPages,
      showFirstLastNavButtons,
      handlePagination: page => {
        this.scrollToTop();
        handlePagination(page);
      },
    };

    return <Pagination {...props} />;
  }
}
